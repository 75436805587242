import React from 'react'
import { Link } from "react-scroll";

export default function Footer() {
    return (
        <>
            <div className='w-full bg-[#374250]'>
                <div className='flex  w-full h-72 bg-[#374250]'>
                    <div className='w-full md:w-1/2'>
                        <div className='w-full pt-14 pl-4 md:pl-10 lg:pl-24 xl:pl-36'>
                            <div className='flex-row'>
                                <a href='/'><img className='w-36 h-36' src='../assets/logo.png' alt='' /></a>
                            </div>
                            <div className='flex w-full pt-4'>
                                <ul className='w-11/12 flex flex-wrap text-white justify-between text-[16px] leading-tight'>
                                    <li className='w-full md:w-auto text-left pl-6 md:pl-0'><Link activeClass='active' to="home" className='text-white'>Inicio</Link></li>
                                    <li className='w-full md:w-auto text-left pl-6 md:pl-0'><Link activeClass='active' to="services" className='text-white'>Servicios</Link></li>
                                    <li className='w-full md:w-auto text-left pl-6 md:pl-0'><Link activeClass='active' to="projects" className='text-white'>Proyectos</Link></li>
                                    <li className='w-full md:w-auto text-left pl-6 md:pl-0'><Link activeClass='active' to="contact" className='text-white'>Contacto</Link></li>
                                </ul>
                            </div>

                        </div>
                        {/* <div className='flex-row w-full pt-4 hidden md:block pl-4 md:pl-10 lg:pl-24 xl:pl-36 text-left'>
                            <p className="text-gray-300 text-sm font-normal leading-normal">© 2023 Grupo Global Digital. Reservados todos los derechos.</p>
                        </div> */}
                    </div>

                    <div className='w-full flex-row  md:w-1/2 hidden md:block'>
                        <div className='w-1/2 h-10 pt-10'></div>
                        <div className='w-full pt-10'>
                            <div className='flex flex-row w-full pl-16 pr-4'>
                                <div className='w-1/5 lg:w-2/5'>

                                </div>
                                <div className='w-4/5 lg:w-3/5'>
                                    <div className='flex flex-row w-full justify-start text-left text-white'>
                                        <img className='w-6 h-6' src='../assets/icons/Pin_fill.png' alt='' />
                                        <p className="pl-2 text-white text-base font-normal leading-relaxed">2no Nivel C.C. Deco City.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row w-full pl-16 pr-4 pt-5'>
                                <div className='w-1/5 lg:w-2/5'>

                                </div>
                                <div className='w-4/5 lg:w-3/5'>
                                    <div className='flex flex-row w-full justify-start text-left text-white'>
                                        <img className='w-6 h-6' src='../assets/icons/Phone_fill.png' alt='' />
                                        <p className="pl-2 text-white text-base font-normal leading-relaxed">(502) 6635-2252</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex-row w-full pt-2 md:hidden '>
                    <div className='w-full pl-2 pr-2'>
                        <div className='flex flex-row w-full justify-start text-left text-white'>
                            <img className='w-6 h-6' src='../assets/icons/Pin_fill.png' alt='' />
                            <p className="pl-2 text-white text-base font-normal leading-relaxed">2no Nivel C.C. Deco City.</p>
                        </div>
                        <div className='flex flex-row w-full justify-start text-left text-white pt-2'>
                            <img className='w-6 h-6' src='../assets/icons/Phone_fill.png' alt='' />
                            <p className="pl-2 text-white text-base font-normal leading-relaxed">(502) 6635-2252</p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-row w-full pt-2 h-20 md:hidden place-content-center'>
                    <p className="text-gray-300 text-xs sm:text-sm font-normal text-justify leading-normal">© 2023 Grupo Global Digital. Reservados todos los derechos.</p>
                </div>
            </div>
        </>
    )
}
